import styled from "@emotion/styled";
import { Link } from "gatsby";
import device from "../helpers/breakpoints";

const ReadLink = styled(Link)`
  color: #276b44;
  display: inline-block;
  font-size: 0.875rem;
  text-decoration: none;
  position: relative;
  font-weight: 400;
  letter-spacing: -0.022em;
  margin-top: 1rem;

  &:after {
    content: '';
    height: 1px;
    position: absolute;
    bottom: -2px;
    left: 0;
    right: 0;
    background: #276b44;
    width: 100%;
    transition: all .2s ease;
    transform-origin: 0 100%;
  }

  @media ${device.tablet} {
    font-size: 16px;

    &:hover {
      &:after {
        width: 0;
      }
    }
  }
`;

export default ReadLink;
