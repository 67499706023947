const Authors = ({ authors = [] }) => {
    if (!authors.length){
        return ('');
    }

    if (authors.length === 1){
        return (authors[0]);
    }

    return (`${authors.slice(0, -1).join(', ')} en ${authors[authors.length - 1]}`);
};

export default Authors;